import {
  Avatar,
  Badge,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import clsx from "clsx";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { useUserContext } from "../context/UserContext";
import { useOurRouter } from "../hooks/useOurRouter";
import { getUserMonogram } from "../utils/users";
import { EditionBadge } from "./billing/EditionBadge";
import { Link } from "./Link";
import { Tooltip } from "./Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    padding: theme.spacing(1.5, "22px", 1.5, 2),
    color: theme.palette.common.white,
    cursor: "pointer",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.075),
    },
  },
  nestedListItem: {
    padding: theme.spacing(1, 1, 1, 8),
  },
  label: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(0, 0, 0, 2),
    whiteSpace: "nowrap",
  },
  navToggle: {
    opacity: 1,
    right: 8,
    transition: theme.transitions.create(["opacity"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      delay: 25,
    }),
  },
  navToggleClosed: {
    opacity: 0,
    transition: theme.transitions.create(["opacity"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
      delay: 0,
    }),
  },
  avatar: {
    height: 32,
    width: 32,
  },
  navIcon: {
    minWidth: 0,
    justifyContent: "center",
    color: theme.palette.common.white,
    opacity: 0.8,
  },
  userName: {
    lineHeight: 1,
    marginTop: theme.spacing(.25),
    maxWidth: 160,
  },
  noMargin: {
    margin: 0,
  },
}));

export const UserNav = () => {
  const classes = useStyles();

  const {
    state: { nav },
  } = useAppContext();

  const [{ user }, actions] = useUserContext();
  const router = useOurRouter<{ date?: string; invite?: number }>();

  const monogram = useMemo(() => getUserMonogram(user), [user]);

  const [open, setOpen] = useState<boolean>(() => router?.pathname?.startsWith("/billing"));

  const handleLogout = useCallback(() => actions?.logout(), [actions]);

  const handleMenuExpand = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(!open);
    },
    [open]
  );

  return (
    <List component="nav" disablePadding className={classes.root}>
      {!!user && (
        <>
          <Link href="/billing" underline="none">
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.navIcon}>
                <Badge badgeContent={0} color="secondary">
                  <Avatar className={classes.avatar} src={user.avatarUrl} alt={user.email} title={user.email}>
                    {monogram}
                  </Avatar>
                </Badge>
              </ListItemIcon>
              <Tooltip title={user.email || ""} placement="right">
                <ListItemText
                  primary={
                    <>
                      <EditionBadge edition={user.editionAfterTrial} size="small" />
                      <Typography className={classes.userName} noWrap>
                        {user.firstName} {user.lastName}
                      </Typography>
                    </>
                  }
                  className={classes.label}
                />
              </Tooltip>
              <ListItemSecondaryAction
                className={clsx(classes.navToggle, {
                  [classes.navToggleClosed]: !nav.open,
                })}
              >
                <IconButton className={classes.navIcon} onClick={handleMenuExpand}>
                  {!open ? (
                    <ChevronRightRoundedIcon fontSize="small" color="inherit" />
                  ) : (
                    <ExpandMoreRoundedIcon fontSize="small" color="inherit" />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Collapse in={open && nav.open} timeout="auto" unmountOnExit>
            <Link href="/billing" underline="none">
              <ListItem className={clsx(classes.listItem, classes.nestedListItem)}>
                <ListItemText className={classes.noMargin} primary="Billing" disableTypography />
              </ListItem>
            </Link>
            <ListItem button className={clsx(classes.listItem, classes.nestedListItem)} onClick={handleLogout}>
              <ListItemText className={classes.noMargin} primary="Log out" disableTypography />
            </ListItem>
          </Collapse>
        </>
      )}
    </List>
  );
};
